<template>
  <div data-section-header class="relative z-10 w-full" v-view="viewHandler">
    <section
      class="relative flex flex-wrap items-center py-20 section_container"
    >
      <header class="w-full mb-5">
        <SmallTitle text="小洪政見未來推動" class="mb-5" />
        <div
          class="flex flex-wrap items-end w-full md:flex-row md:justify-between md:flex-nowrap"
        >
          <div class="overflow-hidden">
            <MainTitle
              data-title
              text="未來的南屯交給小洪"
              color="primary_opacity_light"
              stroke_color="primary_light"
              bg_color="primary_light"
            />
          </div>
        </div>
      </header>
      <div data-content class="relative w-full opacity-0 lg:w-2/3">
        <div
          ref="swiper"
          class="relative z-10 w-full swiper rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
        >
          <div class="w-full swiper-wrapper">
            <div
              v-for="(item, item_index) in gallery_data"
              :key="`image_carousel_${item_index}`"
              class="relative w-full swiper-slide"
              @click="OpenImageDialog"
            >
              <img :src="item" />
            </div>
          </div>
        </div>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-2 translate-y-2 bg-primary_dark rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
        ></div>
      </div>
      <div
        data-content
        class="flex flex-wrap items-center justify-between w-full mt-10 lg:w-1/3 lg:mt-0 lg:justify-center"
      >
        <p class="mb-5 text-base font-bold text-center lg:w-full lg:text-xl">
          PAGE {{ (active_index + 1) | number_text }} /
          {{ gallery_data.length | number_text }}
        </p>
        <div class="flex items-center">
          <button
            @click="ChangeSlide(-1)"
            class="flex items-center justify-center w-16 h-16 mr-2 bg-white rounded-full"
          >
            <LeftArrow class="w-3 text-primary_light" />
          </button>
          <button
            @click="ChangeSlide(1)"
            class="flex items-center justify-center w-16 h-16 ml-2 bg-white rounded-full"
          >
            <RightArrow class="w-3 text-primary_light" />
          </button>
        </div>
      </div>
    </section>
    <div class="relative py-5 mt-0 whitespace-nowrap" data-marquee>
      <p
        v-for="item in 5"
        :key="`marquee_text_1_${item}`"
        class="inline-block mx-5 font-black marquee_text text-6xl opacity-30"
      >
        <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
        <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
      </p>
    </div>
  </div>
</template>

<script>
import gallery_data from '@/assets/refrence/gallery.json';
import RightArrow from '@/components/svg/RightArrow';
import LeftArrow from '@/components/svg/LeftArrow';
import SmallTitle from '@/components/ui/small_title';
import MainTitle from '@/components/ui/main_title.vue';
import { Autoplay } from 'swiper';
import Swiper from 'swiper';
Swiper.use([Autoplay]);
import '@/assets/css/swiper.min.css';

export default {
  name: 'ImageGallery',
  components: {
    LeftArrow,
    RightArrow,
    SmallTitle,
    MainTitle,
  },
  data() {
    return {
      gallery_data: gallery_data,
      swiper: null,
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: 1,
        autoplay: {
          delay: 10000,
        },
      });
    },
    ChangeSlide(val) {
      if (val == -1) {
        this.swiper.slidePrev();
      } else {
        this.swiper.slideNext();
      }
    },
    viewHandler(e) {
      if (e.type == 'exit') {
        this.swiper.autoplay.stop();
      } else {
        this.swiper.autoplay.start();
      }
    },
    OpenImageDialog() {
      this.$store.commit('SetImageModal', {
        status: true,
        image: [],
      });
    },
  },
  computed: {
    active_index() {
      if (this.swiper != null) {
        return this.swiper.realIndex;
      } else {
        return 0;
      }
    },
  },
  filters: {
    number_text(val) {
      return val >= 10 ? val : '0' + val;
    },
  },
  mounted() {
    this.InitSwiper();
  },
};
</script>
