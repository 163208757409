import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page_image_loaded: false,
    main_menu: false,
    main_loading: 0,
    audio_play: false,
    area_dialog: false,
    main_dialog: {
      msg: '',
      status: false,
    },
    image_dialog: {
      status: false,
      image: [],
    },
    video_dialog: {
      status: false,
      video_id: '',
    },
    share_dialog: false,
  },
  getters: {},
  mutations: {
    SetAreaDialog(state, action) {
      state.area_dialog = action;
    },
    SetAudio(status, action) {
      if (
        action &&
        !status.video_dialog.status &&
        !status.image_dialog.status
      ) {
        status.audio_play = action;
      } else if (!action) {
        status.audio_play = action;
      }
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetShareDialog(state, status) {
      state.share_dialog = status;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetImageModal(state, { status, image }) {
      state.image_dialog.status = status;
      state.image_dialog.image = image;
    },
    SetVideoModal(state, { status, video_id }) {
      state.video_dialog.status = status;
      state.video_dialog.video_id = video_id;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {},
  modules: {},
});
