import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import checkView from 'vue-check-view';
import VueMeta from 'vue-meta';
Vue.use(checkView);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
