import gsap from 'gsap';

export class Loading {
  constructor(el) {
    this.el = el;
    this.timer = null;
    this.timeline = null;
  }

  reset() {
    const content = this.el.querySelector('[data-loading-content]');
    const background = this.el.querySelector('[data-loading-bg]');
    const img_box = this.el.querySelector('[data-loading-box]');
    gsap.set(content, { opacity: 0, blur: 2 });
    gsap.set(background, { opacity: 0 });
    gsap.set(img_box, { rotation: 0 });
  }

  open() {
    this.reset();
    const content = this.el.querySelector('[data-loading-content]');
    const background = this.el.querySelector('[data-loading-bg]');
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .set(this.el, {
        x: '100%',
      })
      .fromTo(
        background,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      )
      .fromTo(
        content,
        {
          opacity: 0,
          blur: 2,
        },
        {
          opacity: 1,
          blur: 0,
          onComplete: () => {
            this.loading(180);
          },
        }
      );
  }

  loading(deg) {
    this.timer != null ? clearTimeout(this.timer) : '';
    const img_box = this.el.querySelector('[data-loading-box]');
    gsap.to(img_box, {
      rotation: deg,
      ease: 'power4.inOut',
      duration: 0.6,
      onComplete: () => {
        let next_deg = deg + 180;
        this.timer = setTimeout(() => {
          this.loading(next_deg);
        }, 1000);
      },
    });
  }

  close() {
    this.timer != null ? clearTimeout(this.timer) : '';
    const content = this.el.querySelector('[data-loading-content]');
    const background = this.el.querySelector('[data-loading-bg]');
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .fromTo(
        content,
        {
          opacity: 1,
          blur: 0,
        },
        {
          opacity: 0,
          blur: 2,
        }
      )
      .fromTo(
        background,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        }
      )
      .set(this.el, {
        x: '0',
      });
  }
}
