<template>
  <div>
    <section class="pt-20 pb-10 section_container" data-section-header>
      <header class="w-full mb-0">
        <SmallTitle text="小洪政見已推動" class="mb-5" />
        <div
          class="flex flex-wrap items-end w-full md:flex-row md:justify-between md:flex-nowrap"
        >
          <div class="mb-10 overflow-hidden sm:mb-5">
            <MainTitle
              data-title
              text="小洪做事看得見"
              color="primary_opacity_light"
              stroke_color="primary_light"
              bg_color="primary_light"
            />
          </div>
          <div class="w-full text-right md:w-auto">
            <MainLinkButton
              data-content
              url="/main_politics"
              text="了解更多"
              icon="arrow_right"
              color="primary_dark"
              bg_color="white"
              border_color="primary_light"
              shadow_color="primary_light"
              class="font-bold"
            />
          </div>
        </div>
      </header>
      <div data-content ref="swiper" class="w-full mb-10 swiper">
        <div class="flex items-stretch py-6 swiper-wrapper md:py-8">
          <div
            v-for="(item, item_index) in filter_politics_data"
            :key="`politics_card_${item_index}`"
            class="flex-shrink-0 w-1/3 px-1 swiper-slide"
            style="height: auto"
          >
            <PoliticsCard
              :index="item_index + 1"
              :politics_data="item"
              :progress_data="progress_data"
            />
          </div>
        </div>
      </div>
      <div class="flex items-center">
        <div class="relative flex-1">
          <span
            :style="`width:${
              ((active_index + 1) /
                (filter_politics_data.length - card_per_view + 1)) *
              100
            }%`"
            class="absolute left-0 z-10 block h-2 transition-all duration-300 transform -translate-y-1/2 rounded-full top-1/2 bg-primary_light"
          ></span>
          <span class="h-[1px] w-full bg-zinc-300 block relative z-0"></span>
        </div>
        <div class="flex items-center">
          <button
            @click="ChangeSlide(-1)"
            :class="active_index == 0 ? 'opacity-30' : ''"
            class="flex items-center justify-center mx-2 bg-white rounded-full w-14 h-14"
          >
            <LeftArrow class="w-3 text-primary_light" />
          </button>
          <button
            @click="ChangeSlide(1)"
            :class="
              active_index ==
              Math.ceil((politics_data.length - 1) / card_per_view)
                ? 'opacity-30'
                : ''
            "
            class="flex items-center justify-center bg-white rounded-full w-14 h-14"
          >
            <RightArrow class="w-3 text-primary_light" />
          </button>
        </div>
      </div>
    </section>
    <div class="relative py-5 whitespace-nowrap" data-marquee>
      <p
        v-for="item in 5"
        :key="`marquee_text_1_${item}`"
        class="inline-block mx-5 text-6xl font-black marquee_text opacity-30"
      >
        <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
        <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
      </p>
    </div>
  </div>
</template>

<script>
import politics_data from '@/assets/refrence/politics.json';
import progress_data from '@/assets/refrence/progress.json';
import SmallTitle from '@/components/ui/small_title';
import PoliticsCard from '@/components/home/PoliticsCard.vue';
import MainLinkButton from '@/components/ui/main_link_button.vue';
import LeftArrow from '@/components/svg/LeftArrow.vue';
import RightArrow from '@/components/svg/RightArrow.vue';
import MainTitle from '@/components/ui/main_title.vue';
import { Autoplay } from 'swiper';
import Swiper from 'swiper';
Swiper.use([Autoplay]);
import '@/assets/css/swiper.min.css';

export default {
  name: 'PoliticsList',
  components: {
    SmallTitle,
    PoliticsCard,
    MainLinkButton,
    LeftArrow,
    RightArrow,
    MainTitle,
  },
  data() {
    return {
      playerVars: {
        autoplay: 1,
        loop: 1,
        mute: 1,
        rel: 0,
        controls: 0,
        modestbranding: 1,
      },
      swiper: null,
      politics_data: politics_data,
      progress_data: progress_data,
      window_size: window.innerWidth,
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
        // autoplay: {
        //   delay: 5000,
        // },
      });
    },
    ChangeSlide(val) {
      if (val == -1) {
        this.swiper.slidePrev();
      } else {
        this.swiper.slideNext();
      }
    },
  },
  computed: {
    active_index() {
      if (this.swiper != null) {
        return this.swiper.realIndex;
      } else {
        return 0;
      }
    },
    card_per_view() {
      if (this.window_size <= 640) {
        return 1;
      } else if (this.window_size <= 768) {
        return 2;
      } else {
        return 3;
      }
    },
    filter_politics_data() {
      return this.politics_data.filter((item) => item.progress_id != -1);
    },
  },
  mounted() {
    this.InitSwiper();
    window.addEventListener('resize', () => {
      this.window_size = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.swiper {
  overflow-x: hidden;
  overflow-y: visible;
}
</style>
