import gsap from 'gsap';

export class MainMenu {
  constructor() {
    this.timeline = null;
  }

  open(el) {
    this.timeline != null ? this.timeline.kill() : '';
    let bg = el.querySelectorAll('[data-menu-bg]');
    let menu = el.querySelectorAll('[data-menu]');
    this.timeline = gsap.timeline();

    this.timeline
      .set(
        el,
        {
          x: '-100%',
        },
        'open'
      )
      .fromTo(
        bg,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        },
        'open'
      )
      .fromTo(
        menu,
        {
          x: '100%',
        },
        {
          x: '0%',
          duration: 0.4,
        },
        'open'
      );
  }

  close() {
    this.timeline != null ? this.timeline.reverse() : '';
  }
}
