<template>
  <div class="w-full pt-10 bg-bg_blue" data-section-header>
    <div class="relative py-5 mb-10 whitespace-nowrap" data-marquee>
      <p
        v-for="item in 5"
        :key="`marquee_text_1_${item}`"
        class="inline-block mx-5 text-6xl font-black marquee_text opacity-30"
      >
        <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
        <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
      </p>
    </div>

    <section
      class="relative z-10 flex flex-wrap items-center pb-32 section_container"
    >
      <div
        data-content
        data-scroll-y="0"
        data-scroll-x="0"
        class="w-full mb-10 opacity-0 md:w-1/2 md:mb-0"
      >
        <img
          src="/img/donation.svg"
          class="w-full transform sm:-translate-x-[24%] -translate-x-[10%]"
        />
      </div>

      <div class="w-full md:w-1/2">
        <header class="w-full mb-10">
          <div class="flex flex-col items-end justify-end sm:flex-row-reverse">
            <SmallTitle
              text="政治獻金"
              direction="right"
              color="yellow"
              class="mb-2"
            />
            <div
              class="flex justify-start w-full mb-2 overflow-hidden sm:w-auto"
            >
              <MainTitle
                data-title
                class="whitespace-nowrap"
                text="好政治！"
                color="white"
                stroke_color="secondary"
                bg_color="secondary"
              />
            </div>
          </div>
          <div class="overflow-hidden">
            <MainTitle
              data-title
              class="whitespace-nowrap"
              text="需要有你！"
              color="white"
              stroke_color="secondary"
              bg_color="secondary"
            />
          </div>
        </header>
        <article>
          <header class="mb-3">
            <div class="overflow-hidden">
              <h2
                data-sub-title
                class="inline-block pb-2 text-2xl font-black tracking-wider border-b border-black"
              >
                一場選舉的花費怎麼可能超過市議員四年的收入？
              </h2>
            </div>
          </header>
          <div data-content class="font-medium leading-relaxed tracking-wide">
            <p>
              數十年的兩黨政治瓜分下，政治資源長期受金權把持，選舉成為金權政治的保衛戰，贏得選舉就是利益的延續，反之則代表結束，如此一來，選舉前巨額經費投入與卑劣政治操作的醜態，也就時有所聞，見怪不怪了。小洪的初衷，就是要當一個認真廉潔、為民服務的市議員。<span
                class="inline hight_line"
                >11月26日必須是一場由公民主導的選舉，</span
              >您的每一份人支援及金錢捐助，都讓”好政治 ”與我們更近一步。
            </p>
            <MainHrefButton
              text="我要捐款"
              url="https://donate.newebpay.com/HongXinRen/supporthxr"
              icon="heart"
              :reverse="true"
              color="primary_dark"
              bg_color="secondary"
              border_color="secondary"
              shadow_color="primary_dark"
              class="relative w-full mt-10 text-lg font-bold"
            />
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import SmallTitle from '@/components/ui/small_title.vue';
import MainTitle from '@/components/ui/main_title.vue';
import MainHrefButton from '@/components/ui/main_href_button.vue';
export default {
  name: 'DonationFooter',
  components: {
    SmallTitle,
    MainHrefButton,
    MainTitle,
  },
  data() {
    return {};
  },
};
</script>
