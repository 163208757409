<template>
  <div class="hidden">
    <audio ref="Player" loop>
      <source src="/audio/南屯囝仔_FINAL02.mp3" />
    </audio>
  </div>
</template>

<script>
import { LoadImage } from '@/gsap/image_loaded';
export default {
  name: 'AudioPlugin',
  computed: {
    status() {
      return this.$store.state.audio_play;
    },
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  data() {
    return {
      first_audio_play: -1,
      screenY: window.scrollY,
    };
  },
  watch: {
    status() {
      if (this.status && this.first_audio_play) {
        this.$refs.Player.volume = 0.3;
        this.$refs.Player.play();
      } else {
        this.$refs.Player.pause();
      }
    },
    page_image_loaded() {
      if (this.page_image_loaded) {
        document.addEventListener('touchstart', () => {
          //使用者觸碰過網頁後才能啟用自動播放
          if (this.first_audio_play < 0) {
            this.first_audio_play += 1;
          } else if (this.first_audio_play == 0) {
            this.first_audio_play += 1;
            this.$store.commit('SetAudio', true);
          }
        });
        if (window.innerWidth >= 768) {
          document.addEventListener('scroll', () => {
            this.screenY = window.scrollY;
            //使用者觸碰過網頁後才能啟用自動播放
            if (this.first_audio_play < 0) {
              this.first_audio_play += 1;
            } else if (this.first_audio_play == 0) {
              this.first_audio_play += 1;
              this.$store.commit('SetAudio', true);
            }
          });
        }
      }
    },
  },
  mounted() {
    LoadImage();
    if (window.innerWidth >= 768) {
      document.addEventListener('click', () => {
        //使用者觸碰過網頁後才能啟用自動播放
        if (this.first_audio_play < 0) {
          this.first_audio_play += 1;
        } else if (this.first_audio_play == 0) {
          this.first_audio_play += 1;
          this.$store.commit('SetAudio', true);
        }
      });
    }
  },
};
</script>
