<template>
  <div class="relative pt-32 pb-0 bg-bg_blue" data-section-header>
    <section
      class="flex flex-col items-center md:mb-20 section_container md:flex-row"
    >
      <header class="w-full md:w-1/3">
        <SmallTitle text="南屯區25里政見" class="mb-5" />
        <div class="overflow-hidden">
          <MainTitle
            data-title
            class="whitespace-nowrap"
            text="選擇你的所在地"
            color="white"
            stroke_color="primary_light"
            bg_color="primary_light"
          />
          <!-- <h2 data-title class="text-5xl font-black marquee_text md:text-6xl">
            <span class="text-white">選擇你的所在地</span>
            <span>選擇你的所在地</span>
          </h2> -->
        </div>
      </header>
      <div
        data-content
        data-scroll-y="0"
        data-scroll-x="0"
        class="relative w-full md:w-2/3"
        @mouseenter="map_hover = true"
        @mouseleave="map_hover = false"
      >
        <div
          :class="map_hover ? 'md:opacity-100' : 'md:opacity-0'"
          class="absolute z-10 transition-opacity duration-300 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          <MainButton
            @click="OpenAreaDialog"
            data-content
            text="選擇所在地"
            icon="arrow_right"
            color="primary_dark"
            bg_color="white"
            border_color="primary_light"
            shadow_color="primary_light"
            class="font-bold"
          />
        </div>

        <AreaMap
          class="relative z-0 w-full"
          :hover="map_hover"
          :active_area="active_area"
        />
      </div>
    </section>

    <div class="relative py-5 mb-0 whitespace-nowrap" data-marquee>
      <p
        v-for="item in 5"
        :key="`marquee_text_1_${item}`"
        class="inline-block mx-5 text-6xl font-black marquee_text opacity-30"
      >
        <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
        <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
      </p>
    </div>
  </div>
</template>

<script>
import SmallTitle from '@/components/ui/small_title.vue';
import AreaMap from '@/components/svg/NewAreaMap.vue';
import MainButton from '@/components/ui/main_button.vue';
import MainTitle from '@/components/ui/main_title';
export default {
  name: 'AreaPolitics',
  components: {
    SmallTitle,
    AreaMap,
    MainButton,
    MainTitle,
  },
  data() {
    return {
      active_area: '',
      map_hover: false,
    };
  },
  methods: {
    OpenAreaDialog() {
      this.$store.commit('SetAreaDialog', true);
    },
  },
};
</script>
