<template>
  <div ref="MainLinkButton" class="relative inline-block">
    <router-link
      :to="url"
      @mouseenter.native="hover = true"
      @mouseleave.native="hover = false"
      :class="reverse ? 'flex-row-reverse' : 'flex-row'"
      class="relative z-10 flex items-center justify-center w-full px-8 py-2 transition-all duration-300 transform rounded-full link_btn hover:translate-x-1 hover:translate-y-1"
    >
      <span class="flex-shrink-0" style="font-weight: inherit">{{ text }}</span>
      <LeftArrow
        v-if="icon == 'arrow_left'"
        class="flex-shrink-0 w-4"
        :class="reverse ? 'mr-3' : 'ml-3'"
      />
      <RightArrow
        v-if="icon == 'arrow_right'"
        class="flex-shrink-0 w-4"
        :class="reverse ? 'mr-3' : 'ml-3'"
      />
      <ShareIcon
        v-if="icon == 'share'"
        class="flex-shrink-0 w-4"
        :class="reverse ? 'mr-3' : 'ml-3'"
      />
      <HeartIcon
        v-if="icon == 'heart'"
        class="flex-shrink-0 w-4"
        :class="reverse ? 'mr-3' : 'ml-3'"
      />
    </router-link>
    <div
      ref="ShadowEl"
      class="absolute top-0 left-0 z-0 w-full h-full transform translate-x-1 translate-y-1 rounded-full"
    ></div>
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

import LeftArrow from '@/components/svg/LeftArrow.vue';
import RightArrow from '@/components/svg/RightArrow.vue';
import ShareIcon from '@/components/svg/ShareIcon.vue';
import HeartIcon from '@/components/svg/HeartIcon.vue';
export default {
  name: 'MainLinkButton',
  components: {
    LeftArrow,
    RightArrow,
    ShareIcon,
    HeartIcon,
  },
  props: {
    url: {
      type: String,
      default: '/',
    },
    text: {
      type: String,
      default: '了解更多',
    },
    color: {
      type: String,
    },
    border_color: {
      type: String,
    },
    bg_color: {
      type: String,
    },
    shadow_color: {
      type: String,
    },
    icon: {
      type: String,
      default: 'arrow_left',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      tailwind_colors: [],
    };
  },
  methods: {
    SetColors() {
      const twConfig = resolveConfig(tailwindConfig);
      this.tailwind_colors = twConfig.theme.colors;
    },
    GetColor(val) {
      let color = this.tailwind_colors[val];
      return color ? color : val;
    },
    SetElColor() {
      let button = this.$refs.MainLinkButton.querySelector('.link_btn');
      let shadow = this.$refs.ShadowEl;
      button.style['background-color'] = this.get_bg_color;
      button.style['color'] = this.get_text_color;
      button.style['border'] = '1px solid ' + this.get_border_color;
      shadow.style['background-color'] = this.get_shadow_color;
    },
  },
  computed: {
    get_border_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.border_color;
      } else {
        return this.GetColor(this.border_color);
      }
    },
    get_text_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.color;
      } else {
        return this.GetColor(this.color);
      }
    },
    get_bg_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.bg_color;
      } else {
        return this.GetColor(this.bg_color);
      }
    },
    get_shadow_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.shadow_color;
      } else {
        return this.GetColor(this.shadow_color);
      }
    },
  },
  mounted() {
    this.SetColors();
    this.SetElColor();
  },
};
</script>
