<template>
  <div class="relative z-10 bg-bg_gray">
    <VideoCarousel />
    <About />
    <PoliticsList />
    <ImageGallery />
    <AreaPolitics />
    <Service />
  </div>
</template>

<script>
import VideoCarousel from '@/components/home/VideoCarousel.vue';
import About from '@/components/home/About.vue';
import AreaPolitics from '@/components/home/AreaPolitics.vue';
import PoliticsList from '@/components/home/PoliticsList';
import ImageGallery from '@/components/home/Gallery.vue';
import Service from '@/components/home/Service.vue';
import { LoadImage } from '@/gsap/image_loaded';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'HomeView',
  components: {
    VideoCarousel,
    About,
    AreaPolitics,
    PoliticsList,
    ImageGallery,
    Service,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    LoadImage();
    this.meta_data = GetMetaData('改變南屯新選擇', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
