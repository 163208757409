<template>
  <div class="pr-5 overflow-hidden">
    <h2
      class="relative inline-block text-5xl font-black leading-tight md:text-6xl"
    >
      <span ref="FirstText" class="relative z-10 text-primary_opacity_light">{{
        text
      }}</span>
      <span
        ref="SecondText"
        class="absolute top-0 left-0 z-0 transform translate-x-1 translate-y-1"
        >{{ text }}</span
      >
    </h2>
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

export default {
  name: 'MainTitle',
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
    },
    bg_color: {
      type: String,
    },
    stroke_color: {
      type: String,
    },
  },
  data() {
    return {
      tailwind_colors: [],
    };
  },
  methods: {
    SetColors() {
      const twConfig = resolveConfig(tailwindConfig);
      this.tailwind_colors = twConfig.theme.colors;
    },
    GetColor(val) {
      let color = this.tailwind_colors[val];
      return color ? color : val;
    },
    SetElColor() {
      let first_text = this.$refs.FirstText;
      let second_text = this.$refs.SecondText;
      first_text.style['-webkit-text-stroke'] = '1px ' + this.get_stroke_color;
      first_text.style['color'] = this.get_text_color;
      second_text.style['color'] = this.get_bg_color;
    },
  },
  computed: {
    get_stroke_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.stroke_color;
      } else {
        return this.GetColor(this.stroke_color);
      }
    },
    get_text_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.color;
      } else {
        return this.GetColor(this.color);
      }
    },
    get_bg_color() {
      if (this.tailwind_colors.length <= 0) {
        return this.bg_color;
      } else {
        return this.GetColor(this.bg_color);
      }
    },
  },
  mounted() {
    this.SetColors();
    this.SetElColor();
  },
};
</script>
