<template>
  <div
    v-if="share_dialog"
    class="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full"
  >
    <div class="relative z-10 w-[500px] max-w-[90%]">
      <div
        class="relative z-10 w-full px-20 pb-10 pt-14 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl bg-primary_opacity_light"
      >
        <MainButton
          @click="ShareToFB"
          text="分享至Facebook"
          icon=""
          bg_color="white"
          color="primary_dark"
          border_color="primary_dark"
          shadow_color="primary_dark"
          class="w-full mb-5 font-bold"
        />
        <MainButton
          @click="ShareToLine"
          text="分享至LINE"
          icon=""
          bg_color="white"
          color="primary_dark"
          border_color="primary_dark"
          shadow_color="primary_dark"
          class="w-full mb-5 font-bold"
        />
        <MainButton
          @click="Close"
          text="返回"
          icon="arrow_left"
          :reverse="true"
          color="primary_dark"
          class="w-full font-bold"
        />
      </div>
      <div
        class="absolute top-0 left-0 z-0 w-full h-full transform translate-x-2 translate-y-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl bg-primary_dark"
      ></div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import MainButton from './ui/main_button.vue';
export default {
  components: { MainButton },
  name: 'ShareDialog',
  methods: {
    ShareToFB() {
      window
        .open(
          `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          '_blank'
        )
        .focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
    Close() {
      this.$store.commit('SetShareDialog', false);
    },
  },
  computed: {
    share_dialog() {
      return this.$store.state.share_dialog;
    },
  },
};
</script>
