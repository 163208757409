<template>
  <div
    v-if="status"
    class="fixed top-0 left-0 z-30 flex items-center w-full h-full"
  >
    <div class="relative z-10 w-full">
      <div ref="swiper" class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, item_index) in gallery_data"
            :key="`image_${item_index}`"
          >
            <img
              :src="item"
              class="relative z-0 block object-contain w-full h-full max-h-[80vh]"
            />
          </div>
        </div>

        <button
          @click="ChangeSlide(-1)"
          class="absolute z-10 items-center justify-center hidden mr-0 transform -translate-y-1/2 rounded-full w-14 h-14 bg-primary_dark xl:left-14 left-5 top-1/2 bg-opacity-60 md:flex"
        >
          <LeftArrow class="text-white" />
        </button>
        <button
          @click="ChangeSlide(1)"
          class="absolute z-10 items-center justify-center hidden transform -translate-y-1/2 rounded-full w-14 h-14 bg-primary_dark xl:right-14 right-5 top-1/2 bg-opacity-60 md:flex"
        >
          <RightArrow class="text-white" />
        </button>

        <div
          class="relative flex items-center justify-between px-5 py-5 md:hidden"
        >
          <div class="">
            <MainButton
              color="primary_dark"
              bg_color="white"
              shadow_color="primary_dark"
              border_color="primary_dark"
              icon="close"
              :reverse="true"
              @click="Close"
              class="font-bold"
              text="關閉視窗"
            />
          </div>
          <div class="flex items-center">
            <button
              @click="ChangeSlide(-1)"
              class="z-10 flex items-center justify-center mr-3 transform rounded-full w-14 h-14 bg-primary_dark"
            >
              <LeftArrow class="text-white" />
            </button>
            <button
              @click="ChangeSlide(1)"
              class="z-10 flex items-center justify-center transform rounded-full w-14 h-14 bg-primary_dark"
            >
              <RightArrow class="text-white" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="absolute hidden transform -translate-x-1/2 translate-y-full -bottom-5 left-1/2 md:block"
      >
        <MainButton
          color="primary_dark"
          bg_color="white"
          shadow_color="primary_dark"
          border_color="primary_dark"
          icon="close"
          :reverse="true"
          @click="Close"
          class="font-bold"
          text="關閉視窗"
        />
      </div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import gallery_data from '@/assets/refrence/gallery.json';
import MainButton from '@/components/ui/main_button.vue';
import RightArrow from '@/components/svg/RightArrow.vue';
import LeftArrow from '@/components/svg/LeftArrow.vue';
import Swiper from 'swiper';
import '@/assets/css/swiper.min.css';

export default {
  name: 'ImageDialog',
  components: {
    MainButton,
    RightArrow,
    LeftArrow,
  },
  data() {
    return {
      gallery_data: gallery_data,
      swiper: null,
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        spaceBetween: 0,
      });
      // this.swiper.slideTo(0);
    },
    ChangeSlide(val) {
      if (val == -1) {
        this.swiper.slidePrev();
      } else {
        this.active_index == this.gallery_data.length - 1
          ? this.swiper.slideTo(0)
          : this.swiper.slideNext();
      }
    },
    Close() {
      this.$store.commit('SetImageModal', {
        status: false,
        image: [],
      });
    },
  },
  computed: {
    status() {
      return this.$store.state.image_dialog.status;
    },
    active_index() {
      if (this.swiper != null) {
        return this.swiper.realIndex;
      } else {
        return 0;
      }
    },
  },
  watch: {
    status() {
      if (this.status) {
        document.querySelector('body').style.overflowY = 'hidden';
        this.$nextTick(() => {
          this.InitSwiper();
        });
      } else {
        document.querySelector('body').style.overflowY = 'auto';
      }
    },
  },
  mounted() {
    this.InitSwiper();
  },
};
</script>

<style scoped>
.swiper {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
