/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{html,js,vue}'],
  theme: {
    extend: {
      colors: {
        primary_light: '#00ACA7',
        primary_dark: '#006663',
        primary_opacity_light: '#C7E4E5',
        secondary: '#F8BA3A',
        bg_gray: '#F5F5F5',
        bg_blue: '#C7E4E5',
      },
      fontFamily: {
        // 'clash-display': ['Clash Display', 'Noto Sans TC', 'sans-serif'],
      },
    },
  },
  plugins: [],
};
