<template>
  <footer
    class="relative z-10 w-full pt-40 border-t bg-bg_blue border-primary_dark"
  >
    <div class="absolute top-10 right-10">
      <button
        @click="ScrollToTop"
        class="rounded-full bg-bg_blue relative z-10 border-2 border-primary_dark w-16 h-16 text-center font-black text-primary_dark transition-all duration-300 hover:translate-x-[2px] hover:translate-y-[2px]"
      >
        <TopIcon class="w-4 mx-auto text-primary_dark" />
        Top
      </button>
      <span
        class="block absolute top-0 left-0 w-full h-full rounded-full bg-primary_dark transform translate-x-[2px] translate-y-[2px]"
      ></span>
    </div>
    <div class="relative flex flex-wrap items-start mb-40 section_container">
      <div class="w-full mb-10 md:w-1/2 md:mb-0">
        <SmallTitle text="小洪服務處" class="mb-5" />
        <ol class="pl-5">
          <li class="flex items-center mb-2 text-sm font-medium">
            <p class="flex justify-between w-12 mr-5">
              <span>地</span><span>址</span>
            </p>
            <p>408 台中市南屯區文山里忠勇路52-188號</p>
          </li>
          <li class="flex items-center mb-2 text-sm font-medium">
            <p class="flex justify-between w-12 mr-5">
              <span>電</span><span>話</span>
            </p>
            <p>0976-899-777</p>
          </li>
          <li class="flex items-center text-sm font-medium">
            <p class="w-12 mr-5">E-mail</p>
            <p>hong408818@gmail.com</p>
          </li>
        </ol>
      </div>
      <div
        class="flex flex-wrap justify-between w-full md:w-1/2 sm:justify-start"
      >
        <div class="mb-5 lg:mr-32 md:mr-10 sm:mr-32 sm:mb-0">
          <SmallTitle text="Follow me" class="mb-5" />
          <ol class="flex flex-wrap items-center justify-start pl-5">
            <li class="relative inline-block mb-5 mr-5">
              <a
                target="_blank"
                href="https://www.facebook.com/hong408818"
                class="relative z-10 block p-2 transition-all duration-300 transform bg-primary_dark rounded-tl-xl rounded-tr-xl rounded-bl-xl hover:translate-x-1 hover:translate-y-1"
              >
                <FacebookIcon class="w-5 text-white" />
              </a>
              <span
                class="absolute top-0 left-0 z-0 block w-full h-full transform translate-x-1 translate-y-1 bg-primary_light rounded-tl-xl rounded-tr-xl rounded-bl-xl"
              ></span>
            </li>
            <li class="relative inline-block mb-5 mr-5">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCCOUwl7HxqZ3mE-97SJBFrg"
                class="relative z-10 block p-2 transition-all duration-300 transform bg-primary_dark rounded-tl-xl rounded-tr-xl rounded-bl-xl hover:translate-x-1 hover:translate-y-1"
              >
                <YoutubeIcon class="w-5 h-5 text-white" />
              </a>
              <span
                class="absolute top-0 left-0 z-0 block w-full h-full transform translate-x-1 translate-y-1 bg-primary_light rounded-tl-xl rounded-tr-xl rounded-bl-xl"
              ></span>
            </li>
            <li class="relative inline-block mb-5 mr-5">
              <a
                target="_blank"
                href="https://instagram.com/hong_ren_408"
                class="relative z-10 block p-2 transition-all duration-300 transform bg-primary_dark rounded-tl-xl rounded-tr-xl rounded-bl-xl hover:translate-x-1 hover:translate-y-1"
              >
                <InstagramIcon class="w-5 h-5 text-white" />
              </a>
              <span
                class="absolute top-0 left-0 z-0 block w-full h-full transform translate-x-1 translate-y-1 bg-primary_light rounded-tl-xl rounded-tr-xl rounded-bl-xl"
              ></span>
            </li>
            <li class="relative inline-block mb-5">
              <a
                target="_blank"
                href="https://www.tiktok.com/@hong_ren_408"
                class="relative z-10 block p-2 transition-all duration-300 transform bg-primary_dark rounded-tl-xl rounded-tr-xl rounded-bl-xl hover:translate-x-1 hover:translate-y-1"
              >
                <TicktockIcon class="w-5 h-5 text-white" />
              </a>
              <span
                class="absolute top-0 left-0 z-0 block w-full h-full transform translate-x-1 translate-y-1 bg-primary_light rounded-tl-xl rounded-tr-xl rounded-bl-xl"
              ></span>
            </li>
          </ol>
        </div>
        <div>
          <SmallTitle text="支持小洪" color="yellow" class="mb-5" />
          <a
            target="_blank"
            href="https://donate.newebpay.com/HongXinRen/supporthxr"
            class="inline-flex items-center ml-5 font-bold text-black transition-colors duration-300 border-b border-black hover:text-secondary hover:border-secondary"
          >
            <HeartIcon class="w-4 mr-2" /> 線上捐款
          </a>
        </div>
      </div>
    </div>
    <div class="relative w-full py-3 text-center text-white bg-primary_dark">
      <p class="text-sm">
        網站設計：<a
          class="text-primary_opacity_light"
          target="_blank"
          href="https://www.yongxin-design.com"
          >泳欣事業有限公司</a
        >
        贊助
      </p>
    </div>
  </footer>
</template>

<script>
import SmallTitle from '@/components/ui/small_title.vue';
import FacebookIcon from '@/components/svg/FacebookIcon';
import YoutubeIcon from '@/components/svg/YoutubeIcon.vue';
import InstagramIcon from '@/components/svg/InstagramIcon.vue';
import TicktockIcon from '@/components/svg/TicktokIcon.vue';
import HeartIcon from '@/components/svg/HeartIcon.vue';
import TopIcon from '@/components/svg/TopArrow.vue';
export default {
  name: 'MainFooter',
  components: {
    SmallTitle,
    FacebookIcon,
    YoutubeIcon,
    HeartIcon,
    TopIcon,
    InstagramIcon,
    TicktockIcon,
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
