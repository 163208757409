<template>
  <article
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="relative h-full"
  >
    <div
      class="relative z-10 flex flex-col h-full pt-5 pb-10 pl-5 pr-5 bg-white border-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl border-primary_dark"
    >
      <router-link
        :to="`/politics/${politics_data.politics_id}`"
        class="w-full pb-[56.25%] relative rounded-2xl border-2 border-primary_dark overflow-hidden mb-5 block"
      >
        <img
          :src="politics_data.cover_image"
          class="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <img
          src="/img/loading/2.webp"
          :class="middle_class"
          class="absolute bottom-0 transition-all duration-200 transform right-5 h-2/3"
        />
        <img
          src="/img/loading/1.webp"
          :class="top_class"
          class="absolute bottom-0 left-0 transition-all duration-200 transform h-2/3"
        />
        <img
          src="/img/loading/1.webp"
          :class="left_class"
          class="absolute bottom-0 right-0 transition-all duration-200 transform -rotate-45 h-2/3"
        />
      </router-link>
      <div class="flex items-center mb-3" v-if="progress">
        <p class="mr-2 text-sm font-bold text-primary_dark">處理進度</p>
        <ol class="flex items-stretch flex-1">
          <li
            v-for="item in 5"
            :key="`progress_${item}`"
            :class="
              item == 1
                ? 'rounded-tl-full rounded-bl-full border-r border-zinc-300'
                : item == 5
                ? 'rounded-tr-full rounded-br-full'
                : 'border-r border-zinc-300'
            "
            class="relative w-1/5 h-3 overflow-hidden bg-zinc-200"
          >
            <span
              v-if="item * 20 <= progress.percent"
              class="absolute top-0 bottom-0 left-0 right-0 block bg-primary_dark"
            ></span>
          </li>
        </ol>
      </div>

      <h2
        class="inline-block pb-1 mb-3 text-2xl font-black truncate border-b border-black"
      >
        {{ politics_data.title }}
      </h2>
      <div
        class="flex-1 mb-5 text-sm leading-relaxed tracking-wider text-zinc-600"
        v-html="politics_data.description"
      ></div>
      <ol class="flex items-center -mx-1">
        <li
          v-for="item in politics_data.tags"
          :key="item"
          class="text-xs text-primary_dark font-bold px-2 py-[2px] mx-1 mb-2 rounded-full border border-primary_dark"
        >
          {{ item }}
        </li>
      </ol>

      <div
        class="absolute bottom-0 left-0 flex items-center justify-center w-full transform translate-y-1/2"
      >
        <MainLinkButton
          :url="`/politics/${politics_data.politics_id}`"
          text="了解更多"
          icon="arrow_right"
          color="white"
          bg_color="primary_light"
          border_color="primary_light"
          shadow_color="primary_dark"
          class="relative inline-block font-bold"
        />
      </div>
    </div>
    <div
      class="absolute top-0 left-0 z-0 w-full h-full transform translate-x-1 translate-y-1 bg-primary_dark rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl"
    ></div>
  </article>
</template>

<script>
import MainLinkButton from '@/components/ui/main_link_button.vue';
export default {
  name: 'PoliticsCard',
  components: {
    MainLinkButton,
  },
  props: {
    index: {
      type: Number,
    },
    politics_data: {
      type: Object,
    },
    progress_data: {
      type: Array,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    top_class() {
      return this.hover && this.index % 3 == 2
        ? '-translate-x-6'
        : '-translate-x-[105%]';
    },
    middle_class() {
      return this.hover && this.index % 3 == 1
        ? 'translate-y-0'
        : 'translate-y-[105%]';
    },
    left_class() {
      return this.hover && this.index % 3 == 0
        ? 'translate-x-6'
        : 'translate-x-[105%]';
    },
    progress() {
      return this.progress_data.filter(
        (item) => item.progress_id == this.politics_data.progress_id
      )[0];
    },
  },
};
</script>
