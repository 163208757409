<template>
  <div
    v-if="status"
    class="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full"
  >
    <div
      class="flex flex-col items-center justify-center w-full max-w-screen-lg"
    >
      <div
        v-if="video_id != '' && video_vars != null"
        class="relative z-10 pb-[56.25%] w-full mb-10"
      >
        <youtube
          v-if="video_vars != null"
          class="absolute top-0 left-0 z-10 w-full h-full"
          :player-vars="video_vars"
          :video-id="video_id"
        ></youtube>
      </div>

      <MainButton
        color="primary_dark"
        bg_color="white"
        shadow_color="primary_dark"
        border_color="primary_dark"
        icon="close"
        :reverse="true"
        @click="Close"
        class="font-bold"
        text="關閉視窗"
      />
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import MainButton from '@/components/ui/main_button.vue';
import Vue from 'vue';
import VueYoutube from 'vue-youtube';
Vue.use(VueYoutube);

export default {
  name: 'VideoDialog',
  components: {
    MainButton,
  },
  data() {
    return {
      playerVars: {
        autoplay: 1,
        loop: 1,
        rel: 0,
        modestbranding: 1,
      },
      before_open_music_status: false,
    };
  },
  methods: {
    ChangeSlide(val) {
      if (val == -1) {
        this.swiper.slidePrev();
      } else {
        this.active_index == this.gallery_data.length - 1
          ? this.swiper.slideTo(0)
          : this.swiper.slideNext();
      }
    },
    Close() {
      this.$store.commit('SetVideoModal', {
        status: false,
        video_id: '',
      });
      this.$store.commit('SetAudio', this.before_open_music_status);
    },
  },
  computed: {
    status() {
      return this.$store.state.video_dialog.status;
    },
    video_id() {
      return this.$store.state.video_dialog.video_id;
    },
    video_vars() {
      if (this.video_id == '') {
        return null;
      }
      let tmp_option = Object.assign({}, this.playerVars);
      // tmp_option.playlist = `${this.video_id},${this.video_id}`;
      return tmp_option;
    },
  },
  watch: {
    status() {
      if (this.status) {
        this.before_open_music_status = this.$store.state.audio_play;
        this.$store.commit('SetAudio', false);
        document.querySelector('body').style.overflowY = 'hidden';
      } else {
        document.querySelector('body').style.overflowY = 'auto';
      }
    },
  },
  mounted() {},
};
</script>
