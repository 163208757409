<template>
  <div class="relative" data-tag>
    <div class="relative inline-block">
      <h3
        data-tag-title
        :class="rounded_class + color_class"
        class="relative z-10 inline-block px-8 py-2 font-bold bg-white border"
      >
        {{ text }}
      </h3>
      <div
        data-tag-bg
        :class="rounded_class + bg_class"
        class="absolute top-0 left-0 w-full h-full transform translate-x-1 translate-y-1"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmallTitle',
  props: {
    text: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'left',
    },
    color: {
      type: String,
      default: '',
    },
    shadow_color: {
      type: String,
      default: 'primary_light',
    },
  },
  computed: {
    rounded_class() {
      return this.direction == 'left'
        ? 'rounded-tr-full rounded-tl-full rounded-bl-full '
        : 'rounded-tr-full rounded-tl-full rounded-br-full ';
    },
    color_class() {
      return this.color == ''
        ? 'text-primary_dark border-primary_light'
        : 'text-black border-secondary';
    },
    bg_class() {
      return this.color == ''
        ? this.shadow_color == ''
          ? 'bg-primary_light'
          : 'bg-' + this.shadow_color
        : 'bg-secondary';
    },
  },
};
</script>
