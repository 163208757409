<template>
  <div class="relative">
    <section
      data-section-header
      id="HomeAbout"
      class="relative z-10 py-10 section_container md:py-20"
    >
      <div
        class="flex flex-col-reverse flex-wrap items-start -mx-10 md:flex-row"
      >
        <div class="w-full px-10 md:w-1/2">
          <header class="mb-10">
            <div class="flex items-end overflow-hidden">
              <MainTitle
                data-title
                text="洪鑫任"
                color="primary_opacity_light"
                stroke_color="primary_light"
                bg_color="primary_light"
              />
              <p
                data-title
                class="ml-0 text-2xl font-black text-primary_light md:text-3xl"
              >
                /小洪
              </p>
            </div>
          </header>
          <article
            class="mb-10"
            v-for="(item, item_index) in about_content"
            :key="`about_content_${item_index}`"
          >
            <header class="overflow-hidden">
              <h2
                data-sub-title
                class="inline-block pb-2 text-2xl font-black tracking-wider border-b"
              >
                {{ item.title }}
              </h2>
            </header>

            <div class="overflow-hidden">
              <p
                data-sub-title
                class="font-medium leading-relaxed tracking-wide"
                v-html="item.content"
              ></p>
            </div>
          </article>
          <div data-content>
            <img
              src="/img/Handwriting.webp"
              alt="洪鑫任 台中市南屯區市議員"
              class="max-w-full mb-10"
            />
            <MainLinkButton
              url="/about"
              text="了解更多"
              icon="arrow_right"
              color="primary_dark"
              bg_color="white"
              border_color="primary_light"
              shadow_color="primary_light"
              class="font-bold"
            />
          </div>
        </div>
        <div
          data-image-box
          class="relative w-full px-10 mb-10 md:w-1/2 md:mb-0"
        >
          <img
            src="/img/about.webp"
            alt="洪鑫任 台中市南屯區市議員"
            class="relative z-10 w-full rounded-tl-3xl rounded-tr-3xl rounded-br-3xl"
          />
          <div
            data-image-bg
            class="absolute top-0 bottom-0 z-0 transform translate-x-2 translate-y-2 left-10 right-10 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl bg-primary_light md:translate-x-3 md:translate-y-3"
          ></div>
        </div>
      </div>
    </section>
    <div class="relative py-5 whitespace-nowrap" data-marquee>
      <p
        v-for="item in 5"
        :key="`marquee_text_1_${item}`"
        class="inline-block mx-5 text-6xl font-black marquee_text opacity-30"
      >
        <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
        <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
      </p>
    </div>
  </div>
</template>

<script>
import MainLinkButton from '@/components/ui/main_link_button.vue';
import MainTitle from '@/components/ui/main_title.vue';
import about_content from '@/assets/refrence/about_content.json';
export default {
  name: 'HomeAbout',
  components: {
    MainLinkButton,
    MainTitle,
  },
  data() {
    return {
      about_content: about_content,
    };
  },
};
</script>
