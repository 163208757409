<template>
  <div ref="MainMenu" class="fixed top-0 z-30 w-full h-full -right-full">
    <nav
      data-menu
      class="absolute z-10 top-0 right-0 h-full w-[512px] max-w-full bg-bg_blue rounded-bl-[40px] sm:px-20 px-10 sm:pt-48 pt-10"
    >
      <button
        @click="Close"
        class="absolute z-20 p-2 transition-all duration-300 bg-white rounded-lg text-primary_dark top-10 right-10 hover:bg-primary_dark hover:text-white"
      >
        <CloseIcon class="" />
      </button>
      <div class="mb-20">
        <SmallTitle text="MENU" class="mb-5 md:mb-10" direction="right" />
        <ol class="pl-5">
          <li
            v-for="(item, item_index) in menu_list"
            :key="`menu_list_${item_index}`"
            class="mb-6 text-lg font-bold sm:text-xl"
          >
            <router-link
              @click.native="Close"
              :to="item.link"
              class="pb-1 transition-colors duration-300 border-b text-primary_light border-primary_light hover:text-primary_dark"
            >
              {{ item.title }}
            </router-link>
          </li>

          <li class="mb-6 text-xl font-bold">
            <MainHrefButton
              url="https://donate.newebpay.com/HongXinRen/supporthxr"
              text="支持小洪"
              icon="arrow_right"
              bg_color="secondary"
              border_color="primary_dark"
              shadow_color="primary_dark"
              color="primary_dark"
              class="text-sm font-bold"
            />
          </li>
        </ol>
      </div>
      <div>
        <SmallTitle text="Follow me" class="mb-5 md:mb-10" direction="right" />
        <div class="flex items-center pl-5">
          <div class="relative mr-4">
            <a
              target="_blank"
              href="https://www.facebook.com/hong408818"
              class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-br-xl rounded-tr-xl"
            >
              <FacebookIcon class="w-5 h-5 text-primary_light" />
            </a>
            <div
              class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-br-2xl rounded-tr-2xl"
            ></div>
          </div>
          <div class="relative mr-4">
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCCOUwl7HxqZ3mE-97SJBFrg"
              class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-br-xl rounded-tr-xl"
            >
              <YoutubeIcon class="w-5 h-5 text-primary_light" />
            </a>
            <div
              class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-br-2xl rounded-tr-2xl"
            ></div>
          </div>
          <div class="relative mr-4">
            <a
              target="_blank"
              href="https://instagram.com/hong_ren_408"
              class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-br-xl rounded-tr-xl"
            >
              <InstagramIcon class="w-5 h-5 text-primary_light" />
            </a>
            <div
              class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-br-2xl rounded-tr-2xl"
            ></div>
          </div>
          <div class="relative">
            <a
              target="_blank"
              href="https://www.tiktok.com/@hong_ren_408"
              class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-br-xl rounded-tr-xl"
            >
              <TicktokIcon class="w-5 h-5 text-primary_light" />
            </a>
            <div
              class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-br-2xl rounded-tr-2xl"
            ></div>
          </div>
        </div>
      </div>

      <img
        src="/img/loading/1.webp"
        class="absolute bottom-0 transform -translate-x-1/2 left-1/2"
      />
    </nav>
    <div
      data-menu-bg
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black opacity-0 bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import SmallTitle from '@/components/ui/small_title.vue';
import FacebookIcon from './svg/FacebookIcon.vue';
import YoutubeIcon from './svg/YoutubeIcon.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';
import InstagramIcon from '@/components/svg/InstagramIcon.vue';
import TicktokIcon from '@/components/svg/TicktokIcon.vue';
import { MainMenu } from '@/gsap/main_menu';
import MainHrefButton from '@/components/ui/main_href_button.vue';
export default {
  name: 'MainMenu',
  components: {
    SmallTitle,
    FacebookIcon,
    YoutubeIcon,
    CloseIcon,
    MainHrefButton,
    InstagramIcon,
    TicktokIcon,
  },
  data() {
    return {
      menu_list: [
        {
          title: '首頁',
          link: '/',
        },
        {
          title: '關於小洪',
          link: '/about',
        },
        {
          title: '里民大小事',
          link: '/area_politics',
        },
        {
          title: '小洪政見',
          link: '/main_politics',
        },
        {
          title: '選民服務系統',
          link: '/service',
        },
        {
          title: '聯絡小洪',
          link: '/contact',
        },
      ],
      menu_animation: null,
      window_size: window.innerWidth,
    };
  },
  methods: {
    Open() {
      this.menu_animation.open(this.$refs.MainMenu);
    },
    Close() {
      this.menu_animation.close();
    },
  },
  watch: {
    window_size() {
      if (this.window_size > 768) {
        this.Close();
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.window_size = window.innerWidth;
    });
    this.menu_animation = new MainMenu();
  },
};
</script>
