<template>
  <section
    class="relative z-10 w-full overflow-hidden md:pb-[42.86%] sm:pb-[56.25%] pb-[100%]"
  >
    <div
      class="absolute z-10 w-full text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
    >
      <p class="mb-5 text-xl font-bold text-white sm:font-black">
        落實政治透明化，與民眾溝通零距離
      </p>
      <div class="relative mb-10 overflow-hidden">
        <MainTitle
          data-title
          text="選民服務系統"
          color="primary_opacity_light"
          stroke_color="primary_dark"
          bg_color="primary_dark"
        />
      </div>
      <MainLinkButton
        url="/service"
        text="了解更多"
        icon="arrow_right"
        color="primary_dark"
        bg_color="white"
        border_color="primary_dark"
        shadow_color="primary_dark"
        class="font-bold"
      />
    </div>
    <img
      class="absolute top-0 bottom-0 left-0 right-0 z-0 object-cover w-full h-full"
      src="/img/flow_banner_lg.webp"
    />
  </section>
</template>

<script>
import MainLinkButton from '@/components/ui/main_link_button.vue';
import MainTitle from '@/components/ui/main_title.vue';
export default {
  name: 'HomeService',
  components: {
    MainLinkButton,
    MainTitle,
  },
};
</script>
