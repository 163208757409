import gsap from 'gsap';
import { Timer } from '@/gsap/timer';

export class CircleBullet {
  constructor() {
    this.timer = null;
    this.timeline = null;
    this.active_index = 0;
  }

  setup(el, index) {
    // this.active_index != -1 ? this.clear(el) : '';
    this.timer != null ? this.timer.kill() : '';
    this.clear(el, index);
  }

  clear(el, index) {
    if (el == undefined) {
      return false;
    }
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();
    let active_el = el.querySelectorAll('[data-circle-bullet]')[
      this.active_index
    ];
    let middle_dot = active_el.querySelectorAll('[data-circle-bullet-dot]');
    let circle = active_el.querySelectorAll('circle');
    this.timeline.to(
      circle,
      {
        opacity: 0,
      },
      0
    );
    this.timeline.to(
      middle_dot,
      {
        opacity: 0.3,
        onComplete: () => {
          this.active_index = index;
          this.next(el);
        },
      },
      0
    );
  }

  next(el) {
    let active_el = el.querySelectorAll('[data-circle-bullet]')[
      this.active_index
    ];
    let middle_dot = active_el.querySelectorAll('[data-circle-bullet-dot]');
    let progress_bar = el.querySelectorAll('[data-carousel-progress]');
    let circle = active_el.querySelectorAll('circle');
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    gsap.fromTo(
      progress_bar,
      {
        scaleX: 0,
        transformOrigin: 'left',
      },
      {
        scaleX: 1,
        transformOrigin: 'left',
        duration: 10,
        ease: 'none',
      }
    );

    this.timeline.to(
      middle_dot,
      {
        opacity: 1,
      },
      0
    );
    this.timeline.fromTo(
      circle,
      {
        opacity: 1,
        strokeDasharray: '0 9999',
        stroke: 'black',
      },
      {
        opacity: 1,
        strokeDasharray: '65 9999',
        duration: 10,
      },
      0
    );

    let total_count = el.querySelectorAll('[data-circle-bullet]').length;
    let next_index = -1;
    this.active_index == total_count - 1
      ? (next_index = 0)
      : (next_index = this.active_index + 1);

    this.timer = new Timer(() => {
      this.setup(el, next_index);
    }, 13000);
  }

  pause() {
    this.timer != null ? this.timer.pause() : '';
    this.timeline != null ? this.timeline.pause() : '';
  }

  resume() {
    this.timer != null ? this.timer.resume() : '';
    this.timeline != null ? this.timeline.resume() : '';
  }

  refresh() {
    this.timer != null ? this.timer.kill() : '';
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
    this.timer = null;
    this.active_index = 0;
  }
}
