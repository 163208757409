<template>
  <div id="app" class="relative max-w-full overflow-x-hidden">
    <MainHeader />
    <AudioPlugin />
    <MainLoading />
    <ImageDialog />
    <AreaDialog />
    <VideoDialog />
    <ShareDialog />
    <MainDialog />
    <router-view />
    <DonationFooter />
    <MainFooter />
  </div>
</template>

<style src="@/assets/css/output.css"></style>

<script>
import DonationFooter from '@/components/DonationFooter.vue';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import ImageDialog from '@/components/ImageDialog.vue';
import VideoDialog from '@/components/VideoDialog.vue';
import ShareDialog from '@/components/ShareDialog.vue';
import MainDialog from '@/components/MainDialog.vue';
import AudioPlugin from '@/components/AudioPlugin.vue';
import MainLoading from '@/components/MainLoading.vue';
import AreaDialog from '@/components/AreaDialog.vue';
import { Marquee } from '@/gsap/marquee.js';
import { SectionScroll } from '@/gsap/scroll/section_scroll.js';
// import { ScrollTrigger } from '@/gsap/gsap_loader';
// import ScrollTrigger from 'gsap/ScrollTrigger';
export default {
  name: 'App',
  components: {
    DonationFooter,
    MainFooter,
    MainHeader,
    ImageDialog,
    VideoDialog,
    ShareDialog,
    MainDialog,
    AudioPlugin,
    MainLoading,
    AreaDialog,
  },
  data() {
    return {
      marquee_animation: null,
      section_animation: null,
    };
  },
  methods: {
    RefreshAllEffect() {
      // ScrollTrigger.refresh();
      this.marquee_animation.refresh();
      this.section_animation.refresh();
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        setTimeout(() => {
          // ScrollTrigger.refresh();
        }, 200);
      }
    },
    $route(from, to) {
      this.$store.commit('SetImageLoaded', false);
      this.$nextTick(() => {
        if (from.name != to.name) {
          this.marquee_animation.refresh();
          this.section_animation.refresh();
          // ScrollTrigger.refresh();
        }
      });
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    this.marquee_animation = new Marquee();
    this.section_animation = new SectionScroll();
  },
};
</script>
