var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"pt-20 pb-10 section_container",attrs:{"data-section-header":""}},[_c('header',{staticClass:"w-full mb-0"},[_c('SmallTitle',{staticClass:"mb-5",attrs:{"text":"小洪政見已推動"}}),_c('div',{staticClass:"flex flex-wrap items-end w-full md:flex-row md:justify-between md:flex-nowrap"},[_c('div',{staticClass:"mb-10 overflow-hidden sm:mb-5"},[_c('MainTitle',{attrs:{"data-title":"","text":"小洪做事看得見","color":"primary_opacity_light","stroke_color":"primary_light","bg_color":"primary_light"}})],1),_c('div',{staticClass:"w-full text-right md:w-auto"},[_c('MainLinkButton',{staticClass:"font-bold",attrs:{"data-content":"","url":"/main_politics","text":"了解更多","icon":"arrow_right","color":"primary_dark","bg_color":"white","border_color":"primary_light","shadow_color":"primary_light"}})],1)])],1),_c('div',{ref:"swiper",staticClass:"w-full mb-10 swiper",attrs:{"data-content":""}},[_c('div',{staticClass:"flex items-stretch py-6 swiper-wrapper md:py-8"},_vm._l((_vm.filter_politics_data),function(item,item_index){return _c('div',{key:`politics_card_${item_index}`,staticClass:"flex-shrink-0 w-1/3 px-1 swiper-slide",staticStyle:{"height":"auto"}},[_c('PoliticsCard',{attrs:{"index":item_index + 1,"politics_data":item,"progress_data":_vm.progress_data}})],1)}),0)]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"relative flex-1"},[_c('span',{staticClass:"absolute left-0 z-10 block h-2 transition-all duration-300 transform -translate-y-1/2 rounded-full top-1/2 bg-primary_light",style:(`width:${
            ((_vm.active_index + 1) /
              (_vm.filter_politics_data.length - _vm.card_per_view + 1)) *
            100
          }%`)}),_c('span',{staticClass:"h-[1px] w-full bg-zinc-300 block relative z-0"})]),_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"flex items-center justify-center mx-2 bg-white rounded-full w-14 h-14",class:_vm.active_index == 0 ? 'opacity-30' : '',on:{"click":function($event){return _vm.ChangeSlide(-1)}}},[_c('LeftArrow',{staticClass:"w-3 text-primary_light"})],1),_c('button',{staticClass:"flex items-center justify-center bg-white rounded-full w-14 h-14",class:_vm.active_index ==
            Math.ceil((_vm.politics_data.length - 1) / _vm.card_per_view)
              ? 'opacity-30'
              : '',on:{"click":function($event){return _vm.ChangeSlide(1)}}},[_c('RightArrow',{staticClass:"w-3 text-primary_light"})],1)])])]),_c('div',{staticClass:"relative py-5 whitespace-nowrap",attrs:{"data-marquee":""}},_vm._l((5),function(item){return _c('p',{key:`marquee_text_1_${item}`,staticClass:"inline-block mx-5 text-6xl font-black marquee_text opacity-30"},[_c('span',{staticClass:"text-white"},[_vm._v("改變南屯，新選擇！讓南屯有更好的選擇")]),_c('span',[_vm._v("改變南屯，新選擇！讓南屯有更好的選擇")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }