<template>
  <section
    ref="MainContent"
    id="VideoCarousel"
    class="relative z-10 w-full pt-24 md:pt-0"
    v-view="viewHandler"
  >
    <div class="relative mb-20 md:pr-20">
      <ol
        class="absolute right-0 flex flex-row md:bottom-0 sm:-bottom-14 -bottom-16 md:px-4 md:flex-col"
      >
        <li
          class="block mx-1 md:mb-1"
          v-for="(item, item_index) in video_carousel_data"
          :key="`bullet_${item_index}`"
        >
          <button
            class="relative"
            data-circle-bullet
            @click="SlideTo(item_index)"
          >
            <svg class="w-6 h-6 transform -rotate-90">
              <circle
                r="10"
                cx="12"
                cy="12"
                fill="transparent"
                stroke="black"
                stroke-dasharray="0 9999"
                stroke-width="1"
              />
            </svg>
            <i
              data-circle-bullet-dot
              class="absolute block w-1 h-1 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full opacity-30 top-1/2 left-1/2"
            ></i>
          </button>
        </li>
      </ol>
      <div
        class="absolute z-[11] flex items-center w-2/3 px-4 py-2 transform -translate-y-1/2 bg-white border rounded-tl-full rounded-tr-full rounded-bl-full md:w-auto sm:w-1/2 md:px-8 xl:bottom-10 md:bottom-5 md:top-auto top-full md:translate-y-0 xl:left-20 md:left-10 left-5 border-primary_dark overflow-hidden"
      >
        <div
          data-carousel-progress
          class="absolute top-0 bottom-0 left-0 right-0 z-[9] bg-white mix-blend-exclusion"
        ></div>
        <div
          data-carousel-progress
          class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-primary_dark mix-blend-lighten"
        ></div>
        <SpeakerIcon class="w-6 mr-2 md:w-8 md:mr-4" />
        <h3 class="text-sm font-bold xl:text-3xl sm:text-base">
          {{ video_carousel_data[active_index].title }}
        </h3>
      </div>
      <div ref="swiper" class="swiper w-full relative z-10 rounded-br-[50px]">
        <div class="w-full swiper-wrapper">
          <article
            v-for="(item, item_index) in video_carousel_data"
            :key="`video_carousel_${item_index}`"
            class="w-full pb-[56.25%] relative swiper-slide overflow-hidden"
          >
            <div
              class="absolute top-0 left-0 z-[11] right-0 bottom-0 bg-primary_dark cover_box"
            ></div>
            <div
              @click="OpenVideo(item.youtube_id)"
              class="absolute top-0 bottom-0 left-0 right-0 z-10"
            ></div>
            <youtube
              :ref="`YoutubePlayer${item_index}`"
              class="absolute top-0 left-0 z-0 w-full h-full"
              :player-vars="GetVideoOption(item.youtube_id)"
              @ready="PlayYT(item_index)"
            ></youtube>
          </article>
        </div>
      </div>
      <div
        class="absolute z-0 top-0 left-0 md:right-20 right-0 bottom-0 bg-white rounded-br-[50px]"
      ></div>
      <div
        class="absolute z-[-1] top-0 left-0 md:right-20 right-0 bottom-0 bg-primary_dark rounded-br-[50px] transform md:translate-x-5 md:translate-y-5 translate-y-3"
      ></div>
    </div>
    <div class="relative z-10 w-full overflow-hidden">
      <div class="relative whitespace-nowrap md:py-5" data-marquee>
        <p
          v-for="item in 5"
          :key="`marquee_text_1_${item}`"
          class="inline-block mx-5 text-6xl font-black marquee_text opacity-30"
        >
          <span class="text-white">改變南屯，新選擇！讓南屯有更好的選擇</span>
          <span>改變南屯，新選擇！讓南屯有更好的選擇</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import video_carousel_data from '@/assets/refrence/video_carousel.json';
import Vue from 'vue';
import VueYoutube from 'vue-youtube';
Vue.use(VueYoutube);

import { Autoplay } from 'swiper';
import Swiper from 'swiper';
Swiper.use([Autoplay]);
import '@/assets/css/swiper.min.css';
import SpeakerIcon from '@/components/svg/SpeakerIcon.vue';
import { CircleBullet } from '@/gsap/carousel/circle_bullet';

export default {
  name: 'VideoCarousel',
  components: {
    SpeakerIcon,
  },
  data() {
    return {
      active_player: null,
      video_carousel_data: video_carousel_data,
      playerVars: {
        autoplay: 1,
        loop: 1,
        mute: 1,
        rel: 0,
        controls: 0,
        modestbranding: 1,
      },
      swiper: null,
      bullet_animate: null,
    };
  },
  methods: {
    InitSwiper() {
      this.swiper = null;
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        on: {
          slideChange: () => {
            this.bullet_animate.setup(
              this.$refs.MainContent,
              this.active_index
            );
          },
        },
      });
      this.bullet_animate.setup(this.$refs.MainContent, 0);
    },
    SlideTo(index) {
      this.swiper.slideTo(index);
    },
    viewHandler(e) {
      if (e.type == 'exit') {
        this.swiper.autoplay.stop();
        this.bullet_animate.pause();
      } else {
        this.swiper.autoplay.start();
        this.bullet_animate.resume();
      }
    },
    OpenVideo(id) {
      this.$store.commit('SetVideoModal', {
        status: true,
        video_id: id,
      });
    },
    GetVideoOption(id) {
      let tmp_option = Object.assign({}, this.playerVars);
      tmp_option.playlist = `${id},${id}`;
      return tmp_option;
    },
    PlayYT(index) {
      this.$nextTick(() => {
        if (index == 0) {
          console.log(this.$refs.YoutubePlayer0[0]);
          console.log(this.$refs.YoutubePlayer0[0].player);
          this.$refs.YoutubePlayer0[0].player.playVideo();
        }
        if (index == 1) {
          this.$refs.YoutubePlayer1[0].player.playVideo();
        }
      });
      // let key = `YoutubePlayer${index}`;
      // console.log(this.$refs[key].player, key);
    },
  },
  computed: {
    active_index() {
      if (this.swiper != null) {
        return this.swiper.realIndex;
      } else {
        return 0;
      }
    },
    video_dialog_status() {
      return this.$store.state.video_dialog.status;
    },
  },
  watch: {
    $route() {
      this.bullet_animate.refesh();
    },
    video_dialog_status() {
      if (this.video_dialog_status) {
        this.swiper.autoplay.stop();
        this.bullet_animate.pause();
      } else {
        this.swiper.autoplay.start();
        this.bullet_animate.resume();
      }
    },
  },
  mounted() {
    this.bullet_animate = new CircleBullet();
    this.InitSwiper();
  },
  beforeDestroy() {
    this.bullet_animate.refresh();
    this.swiper.destroy;
  },
};
</script>

<style scoped>
.swiper-slide-active .cover_box {
  animation: Cover 0.6s forwards ease-in-out;
  animation-delay: 0.2s;
}

@keyframes Cover {
  0% {
    /* transform: translateX(0%); */
    transform: scaleX(1);
    transform-origin: left;
  }
  100% {
    /* transform: translateX(-100%); */
    transform: scaleX(0);
    transform-origin: left;
  }
}
</style>
