<template>
  <div
    v-if="status"
    class="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full"
  >
    <div class="relative z-10 w-[500px] max-w-[90%]">
      <div
        class="relative z-10 w-full px-20 pb-10 text-center pt-14 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl bg-primary_opacity_light"
      >
        <div
          class="mb-8 font-bold text-center text-primary_dark"
          v-html="msg"
        ></div>
        <MainButton
          @click="Close"
          text="我知道了"
          bg_color="white"
          shadow_color="primary_dark"
          icon="arrow_right"
          color="primary_dark"
          class="mx-auto font-bold"
        />
      </div>
      <div
        class="absolute top-0 left-0 z-0 w-full h-full transform translate-x-2 translate-y-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl bg-primary_dark"
      ></div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import MainButton from './ui/main_button.vue';
export default {
  components: { MainButton },
  name: 'MainDialog',
  methods: {
    Close() {
      this.$store.commit('SetMainDialog', { action: false, msg: '' });
    },
  },
  computed: {
    status() {
      return this.$store.state.main_dialog.status;
    },
    msg() {
      return this.$store.state.main_dialog.msg;
    },
  },
};
</script>
