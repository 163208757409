<template>
  <nav class="fixed top-0 left-0 z-20 flex items-center justify-between w-full">
    <div class="pt-3 header md:pt-0">
      <router-link
        to="/"
        class="block lg:w-64 sm:w-48 sm:py-3 py-2 sm:px-0 px-6 text-center bg-bg_gray md:rounded-br-[40px] rounded-br-2xl md:rounded-tr-none rounded-tr-2xl"
      >
        <img src="/img/logo.webp" class="block w-24 mx-auto lg:w-32" />
      </router-link>
    </div>
    <div class="flex items-center pt-5 pr-5 lg:pr-10 md:pt-3">
      <div class="relative hidden md:block">
        <ol
          class="relative z-10 flex items-center px-5 bg-white rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        >
          <li
            v-for="(item, item_index) in menu_list"
            :key="`menu_list_${item_index}`"
          >
            <router-link
              :to="item.link"
              class="block px-3 py-2 text-sm font-bold transition-colors duration-300 hover:text-primary_light"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ol>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_dark rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        ></div>
      </div>
      <div class="relative mr-3 sm:mx-5">
        <a
          target="_blank"
          href="https://donate.newebpay.com/HongXinRen/supporthxr"
          class="relative z-10 block px-4 py-2 text-sm font-bold hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-secondary rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl transition-all duration-300"
        >
          支持小洪
        </a>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-secondary rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        ></div>
      </div>
      <div class="relative hidden mr-3 sm:mr-5 md:block">
        <router-link
          to="/contact"
          class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-bl-xl rounded-tr-xl"
        >
          <MailIcon class="w-5 h-5 text-primary_light" />
        </router-link>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        ></div>
      </div>
      <div class="relative mr-3 sm:mr-5 md:mr-0">
        <button
          @click="SetMusic"
          :class="audio_play ? 'bg-white' : 'bg-primary_dark'"
          class="relative z-10 block px-2 py-2 font-bold transition-all hover:translate-x-[3px] hover:translate-y-[3px] duration-300 rounded-tl-xl rounded-bl-xl rounded-tr-xl"
        >
          <SoundIcon v-if="audio_play" class="w-5 h-5 text-primary_light" />
          <MuteIcon v-else class="w-5 h-5 text-white" />
        </button>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        ></div>
      </div>
      <div class="relative block md:hidden">
        <button
          @click="OpenMenu"
          class="relative z-10 block px-2 py-2 font-bold transition-all duration-300 hover:translate-x-[3px] hover:translate-y-[3px] bg-white hover:text-primary_light rounded-tl-xl rounded-bl-xl rounded-tr-xl"
        >
          <MenuIcon class="w-5 h-5 text-primary_light" />
        </button>
        <div
          class="absolute top-0 left-0 w-full h-full transform translate-x-[3px] translate-y-[3px] bg-primary_light rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl"
        ></div>
      </div>
    </div>

    <MainMenu ref="MainMenu" />
  </nav>
</template>

<script>
import MailIcon from '@/components/svg/MailIcon.vue';
import SoundIcon from '@/components/svg/SoundIcon.vue';
import MenuIcon from '@/components/svg/MenuIcon.vue';
import MuteIcon from '@/components/svg/MuteIcon.vue';
import MainMenu from '@/components/MainMenu.vue';
export default {
  name: 'MainHeader',
  components: {
    MailIcon,
    SoundIcon,
    MenuIcon,
    MainMenu,
    MuteIcon,
  },
  data() {
    return {
      menu_list: [
        {
          title: '關於小洪',
          link: '/about',
        },
        {
          title: '里民大小事',
          link: '/area_politics',
        },
        {
          title: '小洪政見',
          link: '/main_politics',
        },
        {
          title: '選民服務系統',
          link: '/service',
        },
      ],
    };
  },
  methods: {
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
    SetMusic() {
      let status = this.$store.state.audio_play;
      this.$store.commit('SetAudio', !status);
    },
  },
  computed: {
    audio_play() {
      return this.$store.state.audio_play;
    },
  },
};
</script>
