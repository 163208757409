<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center"
    v-if="dialog"
  >
    <div class="relative z-10 w-11/12 max-w-screen-md">
      <div
        class="relative z-10 w-full px-5 pt-20 pb-10 bg-white border md:px-10 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl border-primary_dark"
      >
        <button
          @click="Close"
          class="absolute transition-all duration-300 transform top-8 right-8 hover:scale-150"
        >
          <CloseIcon />
        </button>
        <ol class="flex flex-wrap items-center">
          <li
            class="w-1/3 px-1 sm:px-2 md:w-1/5"
            v-for="(item, item_index) in area_data"
            :key="`area_button_${item_index}`"
          >
            <router-link
              :to="`/area_politics/${item.area_id}#Content`"
              @click.native="Close"
              class="block w-full py-2 mb-5 text-sm font-bold text-center transition-colors duration-300 border border-black rounded-full hover:text-white hover:bg-black"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ol>
      </div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 transform translate-x-1 translate-y-1 md:translate-x-3 md:translate-y-3 bg-primary_dark rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
      ></div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 bg-white opacity-40"
    ></div>
  </div>
</template>

<script>
import area_data from '@/assets/refrence/area.json';
import CloseIcon from '@/components/svg/CloseIcon.vue';
export default {
  name: 'AreaDialog',
  components: {
    CloseIcon,
  },
  data() {
    return {
      area_data: area_data,
    };
  },
  methods: {
    Close() {
      this.$store.commit('SetAreaDialog', false);
    },
  },
  computed: {
    dialog() {
      return this.$store.state.area_dialog;
    },
  },
};
</script>
