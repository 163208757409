import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/main_politics',
    name: 'main_politics',
    component: () =>
      import(
        /* webpackChunkName: "main_politics" */ '../views/MainPoliticsList.vue'
      ),
  },
  {
    path: '/area_politics/:id',
    name: 'area_politics',
    component: () =>
      import(
        /* webpackChunkName: "area_politics" */ '../views/AreaPoliticsList.vue'
      ),
  },
  {
    path: '/area_politics',
    redirect: '/area_politics/1',
  },
  {
    path: '/politics/:id',
    name: 'politics',
    component: () =>
      import(/* webpackChunkName: "politics" */ '../views/PoliticsView.vue'),
  },
  {
    path: '/progress/:id',
    name: 'progress',
    component: () =>
      import(/* webpackChunkName: "progress" */ '../views/ProgressView.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () =>
      import(/* webpackChunkName: "service" */ '../views/ServiceView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorView.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to.hash);
      return {
        selector: to.hash,
        offset: { x: 0, y: 100 },
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
