var default_meta = {
  title: '改變南屯即將成真',
  content: '我是小洪，嶄新思維，創造新政治，改變南屯即將成真！',
  image: '/img/share/index.jpg',
};

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title;
  return {
    title: title + ' | 勇敢新南屯-洪鑫任',
    meta: [
      {
        property: 'og:description',
        name: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        name: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        name: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: title + ' | 勇敢新南屯-洪鑫任',
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        name: 'application-name',
        content: title + ' | 勇敢新南屯-洪鑫任',
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        name: 'og:site_name',
        content: '改變南屯即將成真 | 勇敢新南屯-洪鑫任',
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        name: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}
