<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-40 flex items-center justify-center w-full h-full -left-full"
  >
    <div data-loading-content class="relative z-10">
      <div class="relative z-10 overflow-hidden">
        <div
          data-loading-box
          class="absolute bottom-0 z-10 w-full h-full origin-bottom transform -translate-x-1/2 left-1/2"
        >
          <img
            src="/img/loading/1.webp"
            class="absolute bottom-0 z-10 origin-bottom transform -translate-x-1/2 left-1/2"
          />
          <img
            src="/img/loading/2.webp"
            class="absolute bottom-0 z-10 origin-bottom transform rotate-180 -translate-x-1/2 left-1/2"
          />
        </div>
        <img src="/img/loading/Bg.svg" class="relative z-0" />
      </div>
      <p class="mt-5 font-bold text-center text-primary_dark">LOADING...</p>
    </div>

    <div
      data-loading-bg
      class="absolute top-0 left-0 z-0 w-full h-full bg-primary_opacity_light"
    ></div>
  </div>
</template>
<script>
import { Loading } from '@/gsap/loading';
export default {
  name: 'MainLoading',
  data() {
    return {
      loading_tl: null,
    };
  },
  computed: {
    main_loading() {
      return this.$store.state.main_loading;
    },
  },
  watch: {
    main_loading(new_val, old_val) {
      if (old_val == 0 && new_val != 0) {
        this.loading_tl.open();
      } else if (new_val == 0 && old_val != 0) {
        this.loading_tl.close();
      }
    },
  },
  mounted() {
    this.loading_tl = new Loading(this.$refs.MainContent);
  },
};
</script>
